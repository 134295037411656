.map {
  height: 94vh;
  width: 100%;
}

h1 {
  font-size: 30px;
  display: block;
      
}

.buttons,
.citylinks {
    padding: 5px; 
}

.citylinks a {
    color: #17a2b8;
    text-decoration: none;
    border-bottom: 1px dotted #17a2b8;
    margin-right: 10px;
}

      .ol-popup {
        position: absolute;
        background-color: white;
        -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
        filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        width: 400px;
        min-height: 300px;
        font-size: 16px;
      }
      
      .popup-img {
          margin-top: 3px;
      }
      
      .popup-date {
          font-size: 12px;
          color: #666;
      }
      
      
      .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
      }
      .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
      }
      .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
      }
      .ol-popup-closer:after {
        content: "✖";
      }

      

      .layer-switcher {
        left: 8px;
      }
      
      .btn-first {
          top: 85px;          
      }
      
      .btn-second {
          top: 130px;
      }
      
      .btn-third {
          top: 175px;
      }
      
      .btn-4th {
          top: 220px;
      }
      
      .btn-5th {
          top: 265px;
      }
      
      .btn-6th {
          top: 310px;
      }
      
      .btn-7th {
          top: 355px;
      }
      
      
      .layer-switcher button.btn {
        width: 38px;
        height: 38px;
      }
            
      .layer-switcher button.btn-primary,
      .layer-switcher button.btn-primary:focus {
        background-color: rgba(0,60,136,0.7);
      }
      .layer-switcher button.btn-default,
      .layer-switcher button.btn-default:focus {
        background-color: rgba(0,60,136,0.3);
      }
      
      button#layer3.btn-primary {
          background: url('icons/scooter.png') no-repeat rgba(0,60,136,0.7) 50% 50%;
          background-size: 80%;
          
      }
      
      button#layer3.btn-default {
          background: url('icons/scooter.png') no-repeat rgba(0,60,136,0.3) 50% 50%;
          background-size: 80%;
          
      }
      
      
      .fas::before {
         font-size: 24px;
      }

      
      .ol-touch .btn-first {
          top: 85px;          
      }
      
      .ol-touch .btn-second {
          top: 130px;
      }
      
      .ol-touch .btn-third {
          top: 175px;
      }
      
      .ol-touch .btn-4th {
          top: 220px;
      }
      
      .ol-touch .btn-5th {
          top: 265px;
      }
      
      .ol-touch .btn-6th {
          top: 310px;
      }
      
    
      
@media (min-width: 1200px) {
     
}

@media (max-width: 1200px) {
    .ol-popup {
        width: 300px;
        min-height: 225px;
        font-size: 14px;
     }
}

@media (max-width: 992px) {
    .ol-popup {
        width: 200px;
        min-height: 150px;
        font-size: 13px;
     }
}

@media (max-width: 768px) {
  .citylinks a {
    font-size: 12px;
  }
  .ol-popup {
        width: 160px;
        min-height: 120px;
        font-size: 12px;
    }
    .popup-date {
          font-size: 10px;
    }
    
}